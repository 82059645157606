<template>
    <div class="table-responsive">
        <table class="table table-sm">
            <tr>
                <th>{{ $t('crud.pallet_price_scales.pallet_type') }}</th>
                <th>{{ $t('crud.pallet_price_scales.quantity') }}</th>
                <th>{{ $t('crud.pallet_price_scales.price') }}</th>
                <th style="width: 1px;" class="text-right">
                    <button @click="show_new_model = !show_new_model"
                            class="btn btn-sm btn-outline-secondary"><i class="fa fa-plus"></i>
                    </button>
                </th>
            </tr>
            <transition name="fade">
                <tr v-if="show_new_model">
                    <td>
                        <form id="add-price-scale-form" @submit.prevent="_addModel(new_model)"></form>
                        <b-input-group size="sm" style="min-width: 100px">
                            <b-form-select size="sm" v-model="new_model.pallet_type" required form="add-price-scale-form">
                                <template slot="first">
                                    <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                                    <option value="euro">{{ $t('crud.pallet_price_scales.euro_pallet') }}</option>
                                    <option value="block">{{ $t('crud.pallet_price_scales.block_pallet') }}</option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </td>
                    <td>
                        <b-input type="number" :placeholder="$t('crud.pallet_price_scales.quantity')" size="sm" v-model="new_model.quantity" required form="add-price-scale-form"></b-input>
                    </td>
                    <td>
                        <b-input-group prepend="€" size="sm" style="min-width: 100px">
                            <b-form-input v-model="new_model.price" required form="add-price-scale-form" @keypress.native="onlyNumber"></b-form-input>
                        </b-input-group>
                    </td>
                    <td class="text-right">
                        <loading-button class="btn btn-sm btn-outline-secondary" form="add-price-scale-form"
                                type="submit"

                                :title="$t('crud.save')"
                                :loading="loading_new_model"><i class="fa fa-save"></i></loading-button>
                    </td>
                </tr>
            </transition>
            <tr v-for="(model, index) in record.pallet_price_scales">
                <template v-if="!model._editting">
                    <td>{{ model.pallet_type }}</td>
                    <td>{{ model.quantity }}</td>
                    <td>{{ model.price | toCurrency }}</td>
                    <td class="text-right">
                        <div class="btn btn-group btn-group-sm p-0">
                            <loading-button v-if="model._editting"
                                    :loading="model._loading"
                                    class="btn btn-sm btn-outline-secondary"
                                    type="submit"

                                    :title="$t('crud.save')"
                                    :form="'update-price-scale-form-' + model.id"><i class="fa fa-save"></i></loading-button>
                            <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!model._editting"
                                    @click="$set(model, '_editting', true)"
                                    :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                            <loading-button class="btn btn-sm btn-outline-danger"
                                    :loading="loading_models[index]"
                                    @click="_deleteModel(model, index)"
                                    :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                        </div>
                    </td>
                </template>
                <template v-else>
                    <td>
                        <form :id="'update-price-scale-form-' + model.id" @submit.prevent="updateModel(model, index)"></form>
                        <b-input-group prepend="%" size="sm" style="min-width: 100px">
                            <b-form-select size="sm" v-model="model.pallet_type" required form="add-price-scale-form">
                                <template slot="first">
                                    <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                                    <option value="euro">Europallet</option>
                                    <option value="block">Blockpallet</option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </td>
                    <td>
                        <b-input type="number" :placeholder="$t('crud.pallet_price_scales.quantity')" size="sm" v-model="model.quantity" required form="add-price-scale-form"></b-input>
                    </td>
                    <td>
                        <b-input-group prepend="€" size="sm" style="min-width: 100px">
                            <b-form-input v-model="model.price" required form="add-price-scale-form" @keypress.native="onlyNumber"></b-form-input>
                        </b-input-group>
                    </td>
                    <td class="text-right">
                        <div class="btn btn-group btn-group-sm p-0">
                            <loading-button v-if="model._editting"
                                            :loading="model._loading"
                                            class="btn btn-sm btn-outline-secondary"
                                            type="submit"

                                            :title="$t('crud.save')"
                                            :form="'update-price-scale-form-' + model.id"><i class="fa fa-save"></i></loading-button>
                            <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!model._editting"
                                    @click="$set(model, '_editting', true)"
                                    :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                            <loading-button class="btn btn-sm btn-outline-danger"
                                            :loading="loading_models[index]"
                                            @click="deleteModel(model, index)"
                                            :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                        </div>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script>
    import crud from '../crud/mixin_table_crud';
    export default {
        props: ['record'],
        mixins: [crud('pallet-price-scales')],
        data() {
            return {
                new_model: {}
            }
        },
        methods: {
            resetModel() {
                this.new_model = Object.assign({}, {
                    zone_id: this.record.id,
                    pallet_type: undefined,
                    quantity: undefined,
                    price: undefined,
                });
            },
            _addModel(new_model) {
                return this.addModel(new_model).then(() => {
                    this.record.pallet_price_scales.push(new_model);
                });
            },
            _deleteModel(model, index) {
                return this.deleteModel(model, index).then(() => {
                    this.record.pallet_price_scales.splice(index, 1);
                })
            }
        }
    }
</script>
