<template>
    <div>
        <loading-card :loading="!loaded">
            <div class="card-header">{{ $t('crud.zones._show') }}</div>
            <div class="card-body">
                <zones-form-component @submit="updateFromRoute" :record="record"></zones-form-component>
            </div>
        </loading-card>

        <div class="card" v-if="loaded">
            <div class="card-header">{{ $t('crud.zone_regions._plural') }}</div>
            <div class="card-body">
                <zone-regions-component :record="record" type="zones" @created="load" @deleted="load"></zone-regions-component>
            </div>
        </div>
        <div class="card" v-if="loaded">
            <div class="card-header">{{ $t('crud.prices._plural') }}</div>
            <div class="card-body">
                <prices-component :record="record" api-model="zones" @created="load"></prices-component>
            </div>
        </div>
        <div class="card" v-if="loaded">
            <div class="card-header">{{ $t('crud.pallet_price_scales._plural') }}</div>
            <div class="card-body">
                <pallet-price-scales-component :record="record"></pallet-price-scales-component>
            </div>
        </div>
    </div>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import ZonesFormComponent from './Form';
    import PricesComponent from './../_shared/Prices';
    import ZoneRegionsComponent from './../_shared/ZoneRegions';
    import PalletPriceScalesComponent from './../_shared/PalletPriceScales';

    export default {
        name: 'zones-show-component',
        mixins: [functions],
        components: {ZonesFormComponent, PricesComponent, ZoneRegionsComponent, PalletPriceScalesComponent},
        data() {
            return {
                crud: config('zones')
            };
        }
    }
</script>
